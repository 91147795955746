<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <v-data-table
      dense
      :headers="headers"
      :items="data"
      sort-by="id"
      class="elevation-1 p-5"
      :search="search"
      :items-per-page="$dataTableConfig.getItemsPerPage()"
      :footer-props="$dataTableConfig.getFooterProps()"
      :loading-text="$dataTableConfig.getLoadText()"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="d-flex flex-row-reverse"
        >
          <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                dark
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Adicionar Unidade de Medida
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="info"
                dark
              >
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="showForm"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.descricao"
                          label="Descrição"
                          dense
                          outlined
                          :rules="descricaoRule"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.sigla"
                          label="Sigla"
                          dense
                          outlined
                          :rules="siglaRule"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.unidade_base_id"
                          :items="data"
                          label="Unidade de Medida Base"
                          item-text="descricao"
                          item-value="id"
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.fator_conversao"
                          v-money="mask"
                          label="Fator de Conversão"
                          dense
                          outlined
                          reverse
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="editedItem.status"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Status: <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.status)"
                              :text="$label.getStatusMessage(editedItem.status)"
                            />
                          </template>
                        </v-switch>
                      </v-col>

                      <v-col>
                        <v-switch
                          v-model="editedItem.colheita"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Usar na colheita:  <custom-label
                              class="ml-2"
                              :color="$label.getColor(editedItem.colheita)"
                              :text="$label.getMessage(editedItem.colheita)"
                            />
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions v-show="!loading">
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="validate"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-card-text v-else>
                <div class="text-center p-5">
                  <h4 class="mb-4">
                    Aguarde...
                  </h4>
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card class="bg-danger text-white">
              <v-card-title class="headline word-breaker">
                <p>
                  Tem certeza que deseja excluir a unidade de medida  {{ editedItem.descricao }}?
                </p>
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-show="loadingDelete"
                  :size="50"
                  :width="6"
                  color="white"
                  indeterminate
                />
              </v-card-text>

              <v-card-actions v-show="!loadingDelete">
                <v-spacer />
                <v-btn
                  color="white"
                  text
                  outlined
                  large
                  @click="closeDelete"
                >
                  Não
                </v-btn>
                <v-btn
                  color="white"
                  text
                  outlined
                  large
                  @click="deleteItemConfirm"
                >
                  Sim
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="mt-3 mb-1">
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="colheitaFilterValue"
              :items="boolOptions"
              outlined
              dense
              label="Colheita"
            />
          </v-col>
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-select
              v-model="statusFilterValue"
              :items="statusOptions"
              outlined
              dense
              label="Status"
            />
          </v-col>
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.fator_conversao`]="{ item }">
        <span class="num_margin">{{ formatNumber(item.fator_conversao) }}</span>
      </template>

      <template v-slot:[`item.colheita`]="{ item }">
        <custom-label
          :color="$label.getColor(item.colheita)"
          :text="$label.getMessage(item.colheita)"
        />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <custom-label
          :color="$label.getColor(item.status)"
          :text="$label.getStatusMessage(item.status)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-spacer />
        <div v-if="!loading">
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert
                :color="$label.getColor(0)"
                text="Não foi possível obter essas informações"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
  .word-breaker {
    word-break: break-word;
  }
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    loading: false,
    loadingDelete: false,
    showForm: true,
    status: false,
    valid: true,
    descricaoRule: [
      (v) => !!v || 'Necessário informar a descrição da Unidade de Medida',
    ],
    siglaRule: [
      (v) => !!v || 'Necessário informar a sigla da Unidade de Medida',
    ],
    mask: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 4,
      masked: false,
    },
    search: '',
    dialog: false,
    dialogDelete: false,
    statusOptions: [
      {
        text: 'Ativo',
        value: 1,
      },
      {
        text: 'Inativo',
        value: 0,
      },
      {
        text: 'Todos',
        value: '',
      },
    ],
    boolOptions: [
      {
        text: 'Sim',
        value: 1,
      },
      {
        text: 'Não',
        value: 0,
      },
      {
        text: 'Todos',
        value: '',
      },
    ],
    statusFilterValue: '',
    colheitaFilterValue: '',
    data: [],
    editedIndex: -1,
    editedItem: {
      descricao: '',
      sigla: '',
      fator_conversao: 0,
      colheita: 0,
      status: 1,
      unidade_base: {

      },
    },
    defaultItem: {
      descricao: '',
      sigla: '',
      fator_conversao: 0,
      colheita: 0,
      status: 1,
      unidade_base: {

      },
    },
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Adicionar Unidade de Medida' : 'Editar Unidade de Medida'
    },
    headers () {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Sigla', value: 'sigla' },
        { text: 'Unidade Base', value: 'unidade_base.descricao' },
        { text: 'Fator de Conversão', value: 'fator_conversao', align: 'right' },
        {
          text: 'Colheita',
          value: 'colheita',
          filter: (value) => {
            return this.$dataTablefilter(this, 'colheita', value)
          },
        },
        {
          text: 'Status',
          value: 'status',
          filter: (value) => {
            return this.$dataTablefilter(this, 'status', value)
          },
        },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created () {

  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Agrícola', route: 'configuracoes/agricola' },
      { title: 'Unidades de Medida' },
    ])

    this.initialize()
  },
  methods: {
    initialize () {
      this.loading = true

      ApiService.get('/unidade-medida').then((res) => {
        this.data = res.data.data
        this.loading = false
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const index = this.editedIndex

        if (this.editedIndex > -1) {
          ApiService.put(`/unidade-medida/${this.editedItem.id}`, this.editedItem).then((res) => {
            Object.assign(this.data[index], res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Unidade de medida ${this.editedItem.descricao} atualizada com sucesso`)
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível atualizar a Unidade de medida', 'danger')
            console.error('Erro: ', error)
          })
        } else {
          ApiService.post('/unidade-medida', this.editedItem).then((res) => {
            this.data.push(res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Unidade de medida ${this.editedItem.descricao} adicionada com sucesso`)
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar a Unidade de medida', 'danger')
            console.error('Erro: ', error)
          })
        }
      }
    },
    formatNumber (number) {
      return parseFloat(number).toFixed(4).toString().replace('.', ',')
    },
    editItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/unidade-medida/${this.editedItem.id}`).then(() => {
        this.data.splice(this.editedIndex, 1)
        this.closeDelete()
        this.loadingDelete = false
        this.$refs.snackbar.show('Sucesso!', `Unidade de medida ${this.editedItem.descricao} removida com sucesso`)
      }).catch((error) => {
        this.loadingDelete = false
        this.$refs.snackbar.show('Erro', 'Não foi possível remover a Unidade de medida', 'danger')
        console.error('Erro: ', error)
        this.closeDelete()
      })
    },
    close () {
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.editedItem.fator_conversao = 0
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },
  },
}
</script>
